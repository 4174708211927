import { OrbitControls } from "@react-three/drei";
import { useState, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

import Island from "./objects/Island";
import House from "./objects/House";
import RedSail from "./objects/RedSail";
import SeaPlane from "./objects/SeaPlane";
import Clouds from "./objects/Clouds";
import Hologram from "./objects/Hologram";
import FloatingInfo from "./objects/FloatingInfo";
import WorldHelpers from "./hud/WorldHelpers";

import BaseCard from "./text/baseCard";

export default function World({ ready }) {
  const billboardCamPos = new THREE.Vector3(1, -7, -30.69);
  const mapCenter = new THREE.Vector3(0, 0, 0);
  const ghostRef = useRef();

  const [cardIsVisible, setCardIsVisible] = useState(false);
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  const [content, setContent] = useState(null);

  const [startView, setStartView] = useState(true);
  const [orbitIsOn, setOrbitIsOn] = useState(false);
  const [camPosition, setCamPosition] = useState(
    new THREE.Vector3(-60, 8.5, -24)
  );
  const [lookAtPos, setLookAtPos] = useState(
    new THREE.Vector3(-6.95, -4.35, 12.8)
  );

  useFrame((state, delta) => {
    // console.log(state.camera.position);
    state.scene.background = null;
    state.camera.lookAt(ghostRef.current.position);
    if (startView && ready) {
      state.camera.position.lerp(billboardCamPos, delta * 2);
    }
    if (!orbitIsOn && !startView) {
      state.camera.position.lerp(camPosition, delta * 2);
    }
    if (
      ghostRef.current.position.distanceTo(mapCenter) !== 0 &&
      lookAtPos.distanceTo(mapCenter) === 0
    ) {
      ghostRef.current.position.lerp(lookAtPos, delta * 3);
      if (lookAtPos.distanceTo(ghostRef.current.position) < 0.005) {
        ghostRef.current.position.set(0, 0, 0);
      }
    }
  });

  return (
    <>
      <WorldHelpers />
      <OrbitControls
        enabled={orbitIsOn}
        minPolarAngle={Math.PI * 0.25}
        maxPolarAngle={Math.PI * 0.75}
        minDistance={10}
        maxDistance={75}
        enablePan={false}
        rotateSpeed={0.5}
      />
      <group position={[0, -5, 0]}>
        <Island />
        <Clouds />
        <House
          setCardIsVisible={setCardIsVisible}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
        />
        <RedSail
          setCardIsVisible={setCardIsVisible}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
        />
        <SeaPlane
          setCardIsVisible={setCardIsVisible}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
        />
        <Hologram
          setCardIsVisible={setCardIsVisible}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
          setStartView={setStartView}
          setLookAtPos={setLookAtPos}
        />
        <FloatingInfo
          setCardIsVisible={setCardIsVisible}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
        />
        <group ref={ghostRef} position={[-0.75, -7, -21.3]}>
          <mesh position={[0, 5, 0]}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial transparent opacity={0} />
          </mesh>
        </group>
      </group>
      {cardIsVisible && (
        <BaseCard
          setCardIsVisible={setCardIsVisible}
          content={content}
          setInfoIsVisible={setInfoIsVisible}
          orbitIsOn={orbitIsOn}
          setOrbitIsOn={setOrbitIsOn}
        />
      )}
    </>
  );
}
