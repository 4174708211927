import { useGLTF, Html } from "@react-three/drei";
// import { useControls } from "leva";
import StartBoard from "../text/html-projects/StartBoard";

export default function Hologram({
  setInfoIsVisible,
  setOrbitIsOn,
  setStartView,
  setLookAtPos,
}) {
  const hologram = useGLTF("./3d-models/sign.glb");
  const hologramPos = [-0.75, -2.85, -21.5];
  const hologramRot = [-1.6, 3.14, 0.13];
  // const { hologramPos, hologramRot } = useControls({
  //   hologramPos: {
  //     value: [-0.75, -2.85, -20.5],
  //     step: 0.25,
  //   },
  //   hologramRot: {
  //     value: [0, 2.95, 0],
  //     step: 0.05,
  //   },
  // });

  return (
    <>
      <mesh position={hologramPos} rotation={hologramRot}>
        <primitive object={hologram.scene} scale={1} />
        <Html
          distanceFactor={4}
          transform
          occlude
          position={[0.075, 0.1, 0.25]}
          rotation={[-1.6, 0, 0]}
        >
          <StartBoard
            setStartView={setStartView}
            setOrbitIsOn={setOrbitIsOn}
            setLookAtPos={setLookAtPos}
            setInfoIsVisible={setInfoIsVisible}
          />
        </Html>
      </mesh>
    </>
  );
}
