export default function PlaneCard() {
  return (
    <>
      <div className="flex flex-wrap content-between h-[27rem]">
        <div className="w-full">
          <div className="animate-appear">
            <div
              style={{ backgroundImage: "url(./images/me/hack-reactor.png)" }}
              className="relative h-52 md:h-56 max-w-full rounded-lg
              flex bg-slate-500 bg-opacity-20 bg-center"
            >
              <div className="bottom-0 flex flex-wrap overflow-hidden"></div>
            </div>
            <p className="flex justify-between mt-4 mb-1 text-xl md:text2xl font-semibold text-gray-500">
              Education
            </p>
            <div className="">
              <p className="my-1 md:my-2 text-lg md:text-xl text-gray-500">
                With an advanced software engineering certificate from Hack
                Reactor and a versatile skill set across a wide array of
                programming languages and frameworks, I aspire to create
                software that enhances communication, collaboration, and overall
                meaningful human interaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
