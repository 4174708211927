import { useGLTF } from "@react-three/drei";
// import { useControls } from "leva";

export default function Clouds() {
  const clouds = useGLTF("./3d-models/clouds.glb");
  const cloudsPos = [14.25, 30.25, 0];
  const cloudsRot = [0, 2.4, 0];
  // const { cloudsPos, cloudsRot } = useControls({
  //   cloudsPos: {
  //     value: [14.25, 30.25, 0],
  //     step: 0.25,
  //   },
  //   cloudsRot: {
  //     value: [0, 2.4, 0],
  //     step: 0.05,
  //   },
  // });

  return (
    <>
      <mesh position={cloudsPos} rotation={cloudsRot}>
        <primitive object={clouds.scene} scale={1} />
      </mesh>
    </>
  );
}
