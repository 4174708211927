import { useGLTF } from "@react-three/drei";
// import { useControls } from "leva";
import InfoMark from "../text/infoMark";

import PlaneCard from "../text/PlaneCard";

export default function SeaPlane({
  setCardIsVisible,
  setContent,
  setInfoIsVisible,
  infoIsVisible,
  setOrbitIsOn,
  setCamPosition,
}) {
  const seaPlane = useGLTF("./3d-models/plane.glb");

  const planeCam = { x: -1.25, y: 24.37, z: 29.34 };
  const seaPlanePos = [1.5, 16, 12];
  const seaPlaneRot = [-0.6, -1, -0.2];

  // const { seaPlanePos, seaPlaneRot } = useControls({
  //   seaPlanePos: {
  //     value: [1.5, 20, 12],
  //     step: 0.25,
  //   },
  //   seaPlaneRot: {
  //     value: [-0.6, -1, -0.2],
  //     step: 0.05,
  //   },
  // });

  return (
    <>
      <mesh position={seaPlanePos} rotation={seaPlaneRot}>
        <primitive object={seaPlane.scene} scale={1} />
        <InfoMark
          infoPosition={[-4, 2, -1]}
          setCardIsVisible={setCardIsVisible}
          content={<PlaneCard />}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
          cam={planeCam}
        />
      </mesh>
    </>
  );
}
