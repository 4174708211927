// import { useControls } from "leva";
import InfoMark from "../text/infoMark";
import HoloCard from "../text/holoCard";

export default function FloatingInfo({
  setCardIsVisible,
  setContent,
  setInfoIsVisible,
  infoIsVisible,
  setOrbitIsOn,
  setCamPosition,
}) {
  const holoCam = { x: 25, y: 8.8, z: 44.8 };
  const infoPos = [16.75, 6, 10.5];

  // const { infoPos } = useControls({
  //   infoPos: {
  //     value: [16.75, 6, 10.5],
  //     step: 0.25,
  //   },
  // });

  return (
    <>
      <mesh position={infoPos}>
        <InfoMark
          infoPosition={[0, 2, 0]}
          setCardIsVisible={setCardIsVisible}
          content={HoloCard}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
          cam={holoCam}
        />
      </mesh>
    </>
  );
}
