export default function SkillsList() {
  return (
    <div>
      <h1 className="text-center mt-1 mb-1 text-4xl font-semibold text-cyan-400">
        Skills
      </h1>
      <p className="text-center text-xl text-slate-100">my current toolkit</p>
      <div className="w-[30rem] flex flex-wrap justify-center overflow-hidden pointer-events-none">
        <img
          alt="tech"
          src="./svgs/Python.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/React.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Tailwind CSS.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Docker.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Vite.js.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Flask.svg"
          className="inline-block w-16 h-16 rounded-full bg-cyan-300 "
        />
        <img
          alt="tech"
          src="./svgs/MongoDB.svg"
          className="inline-block w-16 h-16 rounded "
        />

        <img
          alt="tech"
          src="./svgs/JavaScript.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Redux.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Bootstrap.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/GitHub.svg"
          className="inline-block w-16 h-16 rounded-full border-2 bg-slate-100 "
        />
        <img
          alt="tech"
          src="./svgs/Django REST.svg"
          className="inline-block w-16 h-16 rounded-full bg-slate-100"
        />
        <img
          alt="tech"
          src="./svgs/FastAPI.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/SQLite.svg"
          className="inline-block w-16 h-16 rounded "
        />

        <img
          alt="tech"
          src="./svgs/Three.js.svg"
          className="inline-block w-16 h-16 rounded-full bg-slate-100"
        />
        <img
          alt="tech"
          src="./svgs/JSON.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/CSS3.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/GitLab.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Insomnia.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/Django.svg"
          className="inline-block w-16 h-16 rounded "
        />
        <img
          alt="tech"
          src="./svgs/PostgresSQL.svg"
          className="inline-block w-16 h-16 rounded "
        />
      </div>
    </div>
  );
}
