export default function WorldIntro({ handleEnter }) {
  return (
    <>
      <div className="w-96 flex flex-col justify-center items-center">
        <h1 className="text-center mb-1 text-4xl font-bold text-cyan-400">
          I'm Santi
        </h1>
        <p className="text-center text-xl text-slate-100">
          Welcome to my world!
        </p>
        <p className="text-center mt-1 text-lg text-slate-100"></p>
        <p className="text-center my-[-.5rem] flex justify-center items-center text-lg text-slate-100">
          Once you see the
          <span
            className="flex w-16 h-16
          rounded-3xl scale-50
          justify-center content-center
          cursor-pointer opacity-80 hover:opacity-100
          bg-gradient-to-r to-cyan-400 from-teal-400
          flex-col items-center animate-marka"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              // slate 100
              className="w-16 h-16 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </span>
          icons
        </p>
        <div className="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 text-slate-100"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 text-slate-100"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </div>

        <p className="text-center my-1 text-lg text-slate-100">
          Click and drag to navigate
        </p>

        <button
          onClick={handleEnter}
          className="w-48 mt-2 bg-gradient-to-r to-cyan-400 from-teal-400 opacity-80
            hover:scale-110 hover:opacity-100 text-gray-800 font-bold py-2 px-2
            text-lg rounded-2xl flex items-center justify-center"
        >
          <span className="text-xl">Explore</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
        </button>
      </div>
    </>
  );
}
