import * as THREE from "three";
import { useState } from "react";
import SkillsList from "./SkillsList";
import WorldIntro from "./WorldIntro";

export default function StartBoard({
  setStartView,
  setOrbitIsOn,
  setLookAtPos,
  setInfoIsVisible,
}) {
  const [greetBoard, setGreetBoard] = useState(true);

  const handleEnter = () => {
    setLookAtPos(new THREE.Vector3(0, 0, 0));
    setStartView(false);
    setTimeout(() => {
      setInfoIsVisible(true);
      setOrbitIsOn(true);
      setGreetBoard(false);
    }, 2000);
  };

  return (
    <div className="flex flex-wrap content-between h-[22rem]">
      {greetBoard ? <WorldIntro handleEnter={handleEnter} /> : <SkillsList />}
    </div>
  );
}
