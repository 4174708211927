import "./App.css";
import { Canvas } from "@react-three/fiber";
import World from "./World";
import { Suspense, useState } from "react";
import { ResizeObserver } from "@juggle/resize-observer";
import { Loader } from "@react-three/drei";

import Hud from "./hud/Hud";

function App() {
  const [ready, setReady] = useState(false);
  const startPos = [-200, 50, -200];

  return (
    <>
      <Suspense>
        <Hud ready={ready} setReady={setReady} />
        <Canvas
          resize={{ polyfill: ResizeObserver }}
          camera={{
            fov: 50,
            near: 0.1,
            far: 500,
            position: startPos,
          }}
          dpr={[1, 2]}
        >
          <World ready={ready} />
        </Canvas>
        <Loader />
      </Suspense>
    </>
  );
}

export default App;
