import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
// import { useControls } from "leva";
import InfoMark from "../text/infoMark";

import HouseCard from "../text/HouseCard";
import { useRef } from "react";

export default function House({
  setCardIsVisible,
  setContent,
  setInfoIsVisible,
  infoIsVisible,
  setOrbitIsOn,
  setCamPosition,
}) {
  const house = useGLTF("./3d-models/workspace.glb");
  const colorMap = useLoader(TextureLoader, "./images/me/coding.png");

  const houseCam = { x: -16, y: 1.65, z: -11.2 };
  const currentPos = useRef();
  const housePos = [-0.25, -1.25, 0.75];
  const houseRot = [0, 0, 0];
  const screenPos = [0.42, 2.84, 0.41];
  const screenRot = [0.56, -2.45, 0.38];

  // const { housePos, houseRot } = useControls({
  //   housePos: {
  //     value: [-0.25, -1.25, 0.75],
  //     step: 0.25,
  //   },
  //   houseRot: {
  //     value: [0, 0, 0],
  //     step: 0.05,
  //   },
  // });

  // const { screenPos, screenRot } = useControls({
  //   screenPos: {
  //     value: [0.42, 2.84, 0.41],
  //     step: 0.25,
  //   },
  //   screenRot: {
  //     value: [0.56, -2.45, 0.38],
  //     step: 0.05,
  //   },
  // });

  return (
    <>
      <mesh position={housePos} rotation={houseRot} currentPos={currentPos}>
        <primitive object={house.scene} scale={1} />
        <InfoMark
          infoPosition={[1, 3.5, 3]}
          setCardIsVisible={setCardIsVisible}
          content={<HouseCard />}
          setContent={setContent}
          setInfoIsVisible={setInfoIsVisible}
          infoIsVisible={infoIsVisible}
          setOrbitIsOn={setOrbitIsOn}
          setCamPosition={setCamPosition}
          cam={houseCam}
        />
        <mesh position={screenPos} rotation={screenRot}>
          <planeGeometry args={[1.7, 1.075]} />
          <meshBasicMaterial map={colorMap} transparent opacity={0.6} />
          <rectAreaLight
            width={1.6}
            height={2}
            intensity={25}
            color={"#dcfdfd"}
            rotation={[0, Math.PI, 0]}
            position={[-0.01, 0.5, -0.01]}
          />
        </mesh>
      </mesh>
    </>
  );
}
