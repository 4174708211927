import { useEffect, useState } from "react";
import MusicPlayer from "./MusicPlayer";

export default function Hud({ setReady, ready }) {
  const [progress, setProgress] = useState(-25);

  useEffect(() => {
    if (!ready) {
      setTimeout(() => {
        setProgress(progress + 25);
        if (progress >= 100) {
          setReady(true);
        }
      }, 500);
    }
    // eslint-disable-next-line
  }, [progress]);

  return (
    <>
      <MusicPlayer />
      <p className="text-3xl text-slate-100 absolute z-10 left-[1rem] top-[1rem]">
        SB
      </p>
      <span className="absolute flex flex-col gap-2 space-x-2 items-end z-10 bottom-[1rem] right-[1rem]">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/santiagobothe/"
          className="hover:scale-125"
        >
          <img
            alt="tech"
            src="./svgs/LinkedIn.svg"
            className="inline-block h-8 w-8 md:h-10 md:w-10 rounded "
          />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://gitlab.com/Santibo10"
          className="hover:scale-125"
        >
          <img
            alt="tech"
            src="./svgs/GitLab.svg"
            className="inline-block h-8 w-8 md:h-10 md:w-10 rounded "
          />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://github.com/santibothe10"
          className="hover:scale-125 rounded-full border-2 border-black"
        >
          <img
            alt="tech"
            src="./svgs/GitHub.svg"
            className="inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-slate-100"
          />
        </a>
      </span>
      {!ready && (
        <div
          className="z-10 flex flex-col w-window h-window justify-center items-center
          bg-gradient-to-t from-[#28313c] to-[#121218]"
        >
          <div className="animate-appear items-center px-5 py-3 flex flex-col ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-20 h-8 rounded-2xl hover:scale-110 text-gray-900 bg-gradient-to-r to-cyan-400 from-teal-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
              />
            </svg>
            <p className="text-center my-1 text-lg text-slate-100">
              preparing world...
            </p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
              <div
                className="bg-gray-600 h-2.5 rounded-full dark:bg-gray-300"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
