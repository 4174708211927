import { Stars, Sparkles } from "@react-three/drei";

export default function WorldHelpers() {
  return (
    <>
      <mesh position={[-199, 50, -199]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial transparent opacity={0} />
      </mesh>

      <directionalLight
        color="#d6e8fe"
        position={[-1, 1, -1]}
        intensity={0.5}
      />
      <directionalLight color="#d6e8fe" position={[1, 1, 1]} intensity={0.5} />
      <directionalLight color="#d6e8fe" position={[-1, 1, 1]} intensity={0.5} />
      <directionalLight color="#d6e8fe" position={[1, 1, -1]} intensity={0.5} />
      <directionalLight color="#d6e8fe" position={[0, -1, 0]} intensity={5} />
      {/* <ambientLight intensity={1} color="#d6e8fe" /> */}
      <Stars
        radius={100}
        depth={50}
        count={5000}
        factor={4}
        saturation={0}
        fade
        speed={1}
      />
      <Sparkles
        size={20}
        scale={[60, 100, 40]}
        position-y={1}
        speed={1}
        count={60}
        fade
      />
    </>
  );
}
