export default function HoloCard() {
  return (
    <>
      <div className="flex flex-wrap content-between h-[27rem]">
        <div className="w-full flex flex-col items-center">
          <div
            style={{ backgroundImage: "url(./images/me/me.png)" }}
            className="mt-1 relative w-52 md:w-56 h-52 md:h-56
            rounded-full bg-cover bg-center bg-no-repeat
            border-4 border-slate-100"
          ></div>
          <h1 className="flex justify-between mt-3 mb-1 text-2xl md:text3xl font-semibold text-cyan-500">
            A bit about me
          </h1>
          <p className="my-1 md:my-2 text-md md:text-lg text-gray-500">
            I'm a software engineer with an interest in security and data
            management. I enjoy working as a full-stack developer and love
            learning new technologies. When I'm not coding, you can find me
            outdoors exploring nature.
          </p>
          <div className="">
            <div className="flex mt-4 space-x-6 justify-center">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/santiagobothe/"
                className="hover:scale-125"
              >
                <img
                  alt="tech"
                  src="./svgs/LinkedIn.svg"
                  className="inline-block h-6 w-6 rounded "
                />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://gitlab.com/Santibo10"
                className="hover:scale-125"
              >
                <img
                  alt="tech"
                  src="./svgs/GitLab.svg"
                  className="inline-block h-6 w-6 rounded "
                />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://github.com/santibothe10"
                className="hover:scale-125"
              >
                <img
                  alt="tech"
                  src="./svgs/GitHub.svg"
                  className="inline-block h-6 w-6 rounded "
                />
              </a>
            </div>
            <p className="select-text text-center my-2 text-md text-gray-500">
              santiagobothe@outlook.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
